import React, { useEffect, useState } from "react";
import "../App.css";
import ReactTooltip from "react-tooltip";
import { Layout } from '../components/Layout';
import { Modal, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import { v4 as uuidv4 } from "uuid";


const WhiteBoard = () => {
    const [isErasing, setIsErasing] = useState(false);
    const [show, setShow] = useState(false);
    const [name, setName] = useState("untitled board");
    const [isSave, setIsSave] = useState(true);
    const [imageToExport, setImageToExport] = useState("");

    const isBrowser = typeof window !== "undefined"

    const id = isBrowser ? window.location.href.split("#id=")[1] : uuidv4;

    const canvasRef = React.createRef();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const clearBoard = () => {
        let canvas = canvasRef.current;
        let context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
    };

    const deleteBoard = () => {
        if (isBrowser) {
            window.localStorage.removeItem(id);
            window.location.href = "/boards";
        }
    };

    const getBoard = () => {
        return localStorage.getItem(id);
    };

    const setEraser = () => {
        document.getElementById("erase").style.color = "black";
        if (isErasing === false) {
            setIsErasing(true);
            document.getElementById("erase").style.backgroundColor = "gray";
        } else {
            setIsErasing(false);
            document.getElementById("erase").style.backgroundColor = "white";
        }
        let canvas = canvasRef.current;
        let context = canvas.getContext("2d");
        context.globalCompositeOperation =
            context.globalCompositeOperation === "destination-out"
                ? "source-over"
                : "destination-out";
        context.strokeStyle =
            context.globalCompositeOperation === "destination-out"
                ? "rgba(255,255,255,1)"
                : document.getElementsByClassName("jscolor")[0].style.backgroundColor;
        context.lineWidth =
            context.globalCompositeOperation === "destination-out" ? 60 : 5;
    };

    const handleSave = () => {
        if (isBrowser) {
            let canvas = canvasRef.current;
            let dataURL = canvas.toDataURL();
            canvas.src = dataURL;
            const imageObj = JSON.stringify({
                id: window.location.href.split("#id=")[1],
                date: new Date().toLocaleDateString("en-US"),
                time: new Date().toLocaleTimeString("en-US"),
                name: name,
                img: dataURL,
            });
            window.localStorage.setItem(
                window.location.href.split("#id=")[1],
                imageObj
            );
        }
        handleClose();
    };

    const handleExport = () => {
        let canvas = canvasRef.current;
        const destinationCanvas = document.createElement("canvas");
        destinationCanvas.width = canvas.width;
        destinationCanvas.height = canvas.height;
        const destCtx = destinationCanvas.getContext("2d");
        destCtx.fillStyle = "#FFFFFF";
        destCtx.fillRect(0, 0, canvas.width, canvas.height);
        destCtx.drawImage(canvas, 0, 0);
        setImageToExport(destinationCanvas.toDataURL());
        setIsSave(false);
        handleShow();
    };

    const showSave = () => {
        setIsSave(true);
        handleShow();
    };

    const drawImage = () => {
        let canvas = canvasRef.current;
        let ctx = canvas.getContext("2d");

        let imageDraw = new Image();
        if (getBoard().length !== 0) {
            imageDraw.onload = function () {
                ctx.drawImage(imageDraw, 0, 0);
            };
            console.log(getBoard());
            imageDraw.src = JSON.parse(getBoard()).img;
        }
    };

    const b64toBlob = (b64Data) => {
        const sliceSize = 512;
        const contentType = "image/png";
        console.log(b64Data);
        const byteCharacters = atob(b64Data.replace("data:image/png;base64,", ""));
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    const downloadImage = () => {
        if (isBrowser) {
            let img = new Blob([b64toBlob(imageToExport)]);
            let url = URL.createObjectURL(img);
            const link = document.createElement("a");

            // Set link's href to point to the Blob URL
            link.href = url;
            link.download = `${name}.png`;

            // Append link to the body
            document.body.appendChild(link);

            // Dispatch click event on the link
            // This is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );

            // Remove link from body
            document.body.removeChild(link);
        }
    };

    useEffect(() => {
        if (getBoard() !== null) {
            drawImage();
            setName(JSON.parse(getBoard()).name);
        }
        if (getBoard() === null) {
            setName("untitled board");
        }
        let canvas = canvasRef.current;
        let context = canvas.getContext("2d");
        let isIdle = true;

        function drawstart(event) {
            function getColor() {
                return document.getElementsByClassName("jscolor")[0].style
                    .backgroundColor;
            }

            document.getElementsByClassName("jscolor")[0].style.color = getColor();
            context.beginPath();
            context.strokeStyle = getColor();
            context.lineWidth = 5;
            context.lineCap = "round";
            context.moveTo(
                event.pageX - canvas.offsetLeft,
                event.pageY - canvas.offsetTop
            );
            isIdle = false;
        }

        function drawmove(event) {
            if (isIdle) return;
            context.lineTo(
                event.pageX - canvas.offsetLeft,
                event.pageY - canvas.offsetTop
            );
            context.stroke();
        }

        function drawend(event) {
            if (isIdle) return;
            drawmove(event);
            isIdle = true;
        }

        function touchstart(event) {
            drawstart(event.touches[0]);
        }

        function touchmove(event) {
            drawmove(event.touches[0]);
            event.preventDefault();
        }

        function touchend(event) {
            drawend(event.changedTouches[0]);
        }

        canvas.addEventListener("touchstart", touchstart, false);
        canvas.addEventListener("touchmove", touchmove, false);
        canvas.addEventListener("touchend", touchend, false);

        canvas.addEventListener("mousedown", drawstart, false);
        canvas.addEventListener("mousemove", drawmove, false);
        canvas.addEventListener("mouseup", drawend, false);

        function resize() {
            if (isBrowser) {
                context.canvas.width = window.innerWidth;
                context.canvas.height = window.innerHeight;
            }
        }

        resize();

        if (isBrowser) {
            window.onorientationchange = function () {
                window.location.reload();
            };
        }
    }, []);

    return (
        <Layout>
            <div className="in-canvas">
                <Helmet>
                    <script
                        key="color-widget"
                        src="https://cdnjs.cloudflare.com/ajax/libs/jscolor/2.0.4/jscolor.min.js"
                        type="text/javascript"
                        async
                    />
                </Helmet>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {isSave ? "Save Board Details" : "Save/Share Image"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isSave ? (
                            <div className="form-group">
                                <label>
                                    <strong>Name&nbsp;</strong>
                                </label>
                                <input placeholder={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                        ) : (
                            <div className="form-group">
                                <img src={imageToExport} className="exportImage" />
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {isSave ? (
                            <Button variant="primary" onClick={handleSave}>
                                Save
                            </Button>
                        ) : (
                            <Button variant="primary" onClick={downloadImage}>
                                Download Image
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
                <ReactTooltip />
                <div className="z-index">
                    <input className="jscolor" defaultValue="000000" />
                    &nbsp;{<span className="boardName">{name}</span>}
                </div>
                <div className="tools">
                    <div>
                        <button onClick={setEraser} id="erase" data-tip="Erase a mistake">
                            <i className="fa fa-eraser"></i>
                        </button>
                    </div>
                    <div>
                        <button onClick={clearBoard} data-tip="Clear the entire board">
                            <i className="fa fa-window-close"></i>
                        </button>
                    </div>
                    <div>
                        <button onClick={showSave} data-tip="Save this board">
                            <i className="fa fa-save"></i>
                        </button>
                    </div>
                    <div>
                        <button onClick={deleteBoard} data-tip="Delete this board">
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                    <div>
                        <button onClick={handleExport} data-tip="Export board">
                            <i className="fa fa-download"></i>
                        </button>
                    </div>
                    <div>
                        <a href="/boards">
                            <button data-tip="Show Boards">
                                <i className="fa fa-backward"></i>
                            </button>
                        </a>
                    </div>
                </div>
                <canvas className="cactus" ref={canvasRef} />
            </div>
        </Layout>
    );
}

export default WhiteBoard;